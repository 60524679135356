import React from 'react'
import {Container} from 'react-bootstrap'
import {SectionWrap, FunFactGridWrap, FunFactGrid} from './section.style'
import Counter from '../../../../components/counter/layout-one'

const Section = () => {
    return (
        <SectionWrap>
            <Container>
                <FunFactGridWrap>
                    <FunFactGrid>
                        <Counter
                            title="We’ve been thriving for"
                            countTo={6}
                            text="EXPERIENCE"
                        />
                    </FunFactGrid>
                    <FunFactGrid>
                        <Counter
                            title="Successfully completed"
                            countTo={82}
                            text="FINISHED PROJECTS"
                        />
                    </FunFactGrid>
                    <FunFactGrid>
                        <Counter
                            title="Multidisciplinary & Value driven"
                            countTo={4}
                            text="AGILE TEAMS"
                        />
                    </FunFactGrid>
                    <FunFactGrid>
                        <Counter
                            title="Worked with more than"
                            countTo={37}
                            text="CLIENTS"
                        />
                    </FunFactGrid>
                </FunFactGridWrap>
            </Container>
        </SectionWrap>
    )
}


export default Section;